<template>
    <div class="row row--15 service-wrapper">
        <div class="col-md-6 col-sm-6 col-12"
						 :class="`col-lg-${12 / itemInOneLine}`"
             v-for="(service, index) in serviceList"
             :key=index>
            <div data-aos="fade-up" data-aos-delay="70">
                <div :class="`service ${serviceStyle} ${checkTextAlign}`">
                    <div class="inner">
                        <div class="image">
                            <img :src="service.image" :alt="service.title" />
                        </div>
                        <div class="content">
                            <h4 class="title">{{ service.title }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceThree',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            },
            serviceList: {
                type: Object
            },
					  itemInOneLine: {
							type: Number,
							default: 3
						}
        },
        data() {
            return {
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>
<style lang="scss">
.col-lg-3 {
	.content {
		.title {
			font-size: 19px;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
</style>
