<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <div class="slider-area slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--70 mt--70"
                            text-align="left"
                            title="城堡数据"
                            description="集成数据分析、情报收集、资产管理的综合数据平台"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-area slider-area-1 slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="mt--60 mb--10" data-aos="fade-up">产品服务</h4>
                    </div>
                    <div class="col-lg-12 mb--60">
                        <div class="service-card" v-for="service in serviceList" data-aos="fade-up">
                            <div class="inner-card row">
                                <div class="image col-lg-2">
                                    <img :src="service.image" alt="">
                                </div>
                                <div class="content col-lg-10">
                                    <div class="title">{{ service.title }}</div>
                                    <div class="description" v-html="service.description"></div>
                                    <a v-if="service.link !== ''" target="_blank" @click="toDetail(service.key)">了解更多>></a>
                                    <span v-else style="color:#FF0003;">开发中</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
		import router from "@/router";

    export default {
        name: 'ServicePage',
        components: {SectionTitle, Layout},
        data() {
            return {
                serviceList: [
                    {
                        image: require('@/assets/images/service/new/s1.png'),
                        title: '商品期货投研系统',
                        description: '城堡期货投研是一个聚焦大宗商品期现货市场投资与研究的媒体和投研服务平台，目前产品根据公司交易员日常操作需要，自研开发期货交易投研系统，致力于通过数据来解读市场。',
												key: 'investment-research',
                    },
                    {
                        image: require('@/assets/images/service/new/s2.png'),
                        title: '城堡Data',
                        description: '城堡Data是一个金融数据包，提供以Java、Python或者HTTP形式访问，主要实现对金融数据从数据采集、清洗加工到数据存储的过程，能够为金融分析人员、交易员提供快速、整洁、和多样的数据，为他们在数据获取方面极大地减轻工作量，使他们更加专注于策略和模型的研究与实现上。<br>目前城堡Data已经集成了期货交易实时/历史数据、股票历史数据、宏观数据、产业链数据、数字货币实时/历史数据、外盘商品历史数据、外汇历史数据，后续将会有更多的数据接入。',
												key: 'data',
                    },
                    {
                        image: require('@/assets/images/service/new/s3.png'),
                        title: '城堡量化交易系统',
                        description: '城堡量化交易系统是集行情、咨询、交易为一体的交易终端，可以帮助用户快速获取市场信息、准确处理和分析交易数据、判断走势、风险及收益，精准把握交易趋势。',
												key: 'trader'
                    },
                    {
                        image: require('@/assets/images/service/new/s4.png'),
                        title: '财务管理系统',
                        description: '帮助实现企业业务管理、数据管理和应用，降低企业成本支出，提高盈利和成本管理，采用成熟的财务模型，使各方信息需求者可以实现数据的多维分析。',
												key: 'finance'
                    },
                    {
                        image: require('@/assets/images/service/new/s6.png'),
                        title: '内外协作办公平台',
                        description: '内外协作办公平台是一套企业级的高效办公、去中心化应用搭建平台，功能覆盖组织管理、业务需求、财务管理、日常办公等诸多方面，有效解决传统办公信息集成难、效率低、成本高等难题，助力企业内外协作办公。',
												key: 'oa'
                    },
                    {
                        image: require('@/assets/images/service/new/s7.png'),
                        title: '客户管理系统',
                        description: '管理维护客户关系，集成行业投资信息与当前投资产品信息，帮助客户了解市场信息和趋势，增强对产品了解和关注。',
												key: 'customer'
                    },
										{
											image: require('@/assets/images/service/new/s14.png'),
											title: '城堡家族办公室',
											description: '聚焦家办业务，包含股权、债权、证券、不动产等多投资品类管理功能；支持各类数据图表分析。通过合理的资产多样化投资及自研的资产配置模型来优化用户资产配置，实现在降低投资组合的波动和风险的同时保证及增加投资收益。',
											key: 'fo'
										}
                ]
            }
        },
				methods: {
					toDetail(key) {
						router.push({
							path: '/service/' + key
						})
					}
				}
    }
</script>
