<template>
    <ul class="mainmenu">
        <li><router-link to="/service">产品服务</router-link></li>
        <li><router-link to="/solution">解决方案</router-link></li>
        <li><router-link to="/data">数据基建</router-link></li>
        <li><router-link to="/brain">城堡大脑</router-link></li>
        <li><router-link to="/about">关于我们</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
