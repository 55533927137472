<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <div class="slider-area slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--70 mt--70"
                            text-align="left"
                            title="数据基建"
                            description="让数据能量，全方位赋能企业需求"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <section class="rn-service-area rn-section-gap">
            <div class="small-container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-6">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="@/assets/images/service/new/d3.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h4 class="title theme-gradient2" data-aos="fade-up" data-aos-delay="280">
																	城堡大模型数据语料库
                                </h4>
                                <p data-aos="fade-up" data-aos-delay="310">
																	随着以ChatGPT、文心一言为代表的AIGC模型的应用普及，行业内在AI写作、AI绘画、数字人等各个应用场景下的训练投入需求也在不断提升。如何获取充足、合规、低成本、准确规范的训练数据也是需求方面临的现实问题。
																	<br>
																	<br>
																	城堡大模型数据语料库旨在立足客户AI模型的实际业务需要，提供从模型训练到用户使用再到AI应用不同阶段下完备的模型数据解决方案。
																</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Separator/>
        <section class="rn-service-area rn-section-gap">
            <div class="small-container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h4 class="title theme-gradient2" data-aos="fade-up" data-aos-delay="280">
																	数据加密存储
                                </h4>
                                <p data-aos="fade-up" data-aos-delay="310">
																	城堡数据防泄密系统，是城堡数据自主研发，集成了访问控制、密码安全、跟踪审核等技术手段，实现对企业电子文件的存储、访问、处理等过程的全防卫保护，该系统利用驱动层投影加密技术实现电子文件安全加密的防护产品，是一套从源头保障数据安全和使用安全的加密系统。
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="@/assets/images/service/new/d4.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'

    export default {
        name: 'ServicePage',
        components: { Separator, SectionTitle, Layout}
    }
</script>
