import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About'
import ServicePage from '../views/ServicePage'
import ServiceDetail from '../views/ServiceDetail'
import Solution from '../views/Solution'
import Data from '../views/Data'
import ErrorPage from '../views/404'
import Brain from "@/views/Brain.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/service',
        meta: {
            noCache: true
        }
    },
    {
        path: '/data',
        name: 'Data',
        component: Data,
        meta: {
            title: '城堡数据',
        },
    },
    {
        path: '/brain',
        name: 'Brain',
        component: Brain,
        meta: {
            title: '城堡数据',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '城堡数据',
        },
    },
    {
        path: '/service',
        name: 'Service',
        component: ServicePage,
        meta: {
            title: '城堡数据',
        },
    },
    {
        path: '/service/:key',
        name: 'ServiceSystem',
        component: ServiceDetail,
        meta: {
            title: '城堡数据',
        },
    },
    {
        path: '/solution',
        name: 'Solution',
        component: Solution,
        meta: {
            title: '城堡数据',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '城堡数据',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
