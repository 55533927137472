<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <div class="slider-area slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--70 mt--70"
                            text-align="left"
                            :title="system.title"
                            :description="system.description"
														title-class="mb--40 text-center"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-area slider-area-1 slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="mt--60 mb--10 text-center" data-aos="fade-up">核心功能模块</h4>
                    </div>
                    <div class="col-lg-12 mb--60">
											<div class="systems">
												<div class="system system-card" v-for="service in system.modules" data-aos="fade-up">
													<div class="inner-card row">
														<div class="image col-lg-3">
															<img :src="service.img" alt="">
														</div>
														<div class="content col-lg-9">
															<div class="title">{{ service.title }}</div>
															<div class="description" v-html="service.description"></div>
														</div>
													</div>
												</div>
											</div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'

    export default {
        name: 'ServicePage',
        components: {SectionTitle, Layout},
        data() {
            return {
                systemData: {
									'investment-research': {
										title: '商品期货投研系统',
										description: '城堡期货投研是一个聚焦大宗商品期现货市场投资与研究的媒体和投研服务平台，目前产品根据公司交易员日常操作需要，自研开发期货交易投研系统，致力于通过数据来解读市场。',
										modules: [
											{
												img: require('@/assets/images/service/system/ir1.png'),
												title: '宏观数据',
												description: '提供全球经济、政治、政策等多方面的数据和信息，帮助投资者理解宏观经济环境对商品期货市场的影响。',
											},
											{
												img: require('@/assets/images/service/system/ir2.png'),
												title: '产业链数据',
												description: '提供商品期货相关产业链的上下游数据，帮助投资者深入了解产业链的运行状况和市场供需关系。',
											},
											{
												img: require('@/assets/images/service/system/ir3.png'),
												title: '财经日历',
												description: '提供全球重要财经事件和数据的发布时间和预期值，帮助投资者提前规划交易策略。',
											},
											{
												img: require('@/assets/images/service/system/ir4.png'),
												title: '7✖24小时快讯',
												description: '提供实时更新的市场新闻、公告、研究报告等信息，帮助投资者及时捕捉市场动态和投资机会。',
											},
										]
									},
									'data': {
										title: '城堡Data',
										description: '城堡Data是一个金融数据包，提供以Java、Python或者HTTP形式访问，主要实现对金融数据从数据采集、清洗加工到数据存储的过程，能够为金融分析人员、交易员提供快速、整洁、和多样的数据，为他们在数据获取方面极大地减轻工作量，使他们更加专注于策略和模型的研究与实现上。',
										modules: [
											{
												img: require('@/assets/images/service/system/d1.png'),
												title: '数据查询',
												description: '提供交易数据、产业链数据、宏观经济数据等多维度多数据源的数据查询、调用。',
											},
											{
												img: require('@/assets/images/service/system/d2.png'),
												title: '行业动态',
												description: '提供金融市场最新的市场趋势分析、政策解读、机构动态等动态发展信息，帮助使用人员快速理解政策影响、跟踪市场动态发展。',
											},
											{
												img: require('@/assets/images/service/system/d3.png'),
												title: '市场情绪',
												description: '通过系统分析交易数据、新闻数据和宏观经济数据等，识别市场情绪的变化，并作出相关预测。',
											},
											{
												img: require('@/assets/images/service/system/d4.png'),
												title: '数据模型',
												description: '在数据获取的基础上，结合AI算法和行业经验，抽象现实世界的数据和信息，结合相关数据算法模型，帮助用户更好地理解和分析金融市场。',
											},
										]
									},
									'trader': {
										title: '城堡量化交易系统',
										description: '城堡量化交易系统是集行情、咨询、交易为一体的交易终端，可以帮助用户快速获取市场信息、准确处理和分析交易数据、判断走势、风险及收益，精准把握交易趋势。',
										modules: [
											{
												img: require('@/assets/images/service/system/t1.png'),
												title: '数据获取及处理',
												description: '从各种数据源（如交易所、数据提供商等）获取市场数据，如股票价格、交易量、行情数据等，并对这些数据进行清洗、预处理和存储。',
											},
											{
												img: require('@/assets/images/service/system/t2.png'),
												title: '策略开发',
												description: '提供允许交易员、开发者使用编程语言进行开发、测试和优化交易策略的策略开发环境。',
											},
											{
												img: require('@/assets/images/service/system/t3.png'),
												title: '风险管理',
												description: '用于管理交易过程中的资本风险，包括设置仓位大小、制定止损和止盈策略、控制风险敞口等。',
											},
											{
												img: require('@/assets/images/service/system/t4.png'),
												title: '回测和仿真',
												description: '允许用户在历史数据上测试交易策略，验证策略的有效性和稳定性，评估策略潜在绩效和风险。',
											},
										]
									},
									'finance': {
										title: '财务管理系统',
										description: '帮助实现企业业务管理、数据管理和应用，降低企业成本支出，提高盈利和成本管理，采用成熟的财务模型，使各方信息需求者可以实现数据的多维分析。',
										modules: [
											{
												img: require('@/assets/images/service/system/f1.png'),
												title: '账户管理',
												description: '主要负责企业银行账户、往来账户、内部账户等各类账户的设置、维护和管理。',
											},
											{
												img: require('@/assets/images/service/system/f2.png'),
												title: '财务审批',
												description: '通过预设的审批流程和规则，对财务申请进行自动化处理，提高审批效率，降低人为错误。',
											},
											{
												img: require('@/assets/images/service/system/f3.png'),
												title: '收入/支出管理',
												description: '用于记录和追踪企业的所有收入和支出，确保财务数据的准确性和完整性。',
											},
											{
												img: require('@/assets/images/service/system/f4.png'),
												title: '财务报表',
												description: '通过汇总和分析财务数据，以表格、图表等形式展示企业的财务状况和经营成果。',
											},
										]
									},
									'oa': {
										title: '内外协作办公平台',
										description: '城堡内外协同办公平台可以帮助企业工作流程自动化，文档共享及管理，高效的分布办公，提高跨部门合作效率，实现集中管理，建立企业信息集成平台。',
										modules: [
											{
												img: require('@/assets/images/service/system/oa1.png'),
												title: '项目管理',
												description: '管理企业项目从启动、立项到执行、监控、收尾的全部项目流程。'
											},
											{
												img: require('@/assets/images/service/system/oa2.png'),
												title: '文档管理',
												description: '对企业日常运营中的商务合同、产品手册、客户资料、项目资料等文档内容以电子形势存储管理。'
											},
											{
												img: require('@/assets/images/service/system/oa3.png'),
												title: '账户管理',
												description: '企业账户管理是一个综合性的过程，旨在确保企业资金的安全、合规和高效运作。'
											},
											{
												img: require('@/assets/images/service/system/oa4.png'),
												title: '消息公告',
												description: '管理企业内部或对外发布的重要信息，向企业员工、股东、合作伙伴、客户及时传达企业的最新动态和决策。'
											}
										]
									},
									'customer': {
										title: '客户管理系统',
										description: '管理维护客户关系，集成行业投资信息与当前投资产品信息，帮助客户了解市场信息和趋势，增强对产品了解和关注。',
										modules: [
											{
												img: require('@/assets/images/service/system/c1.png'),
												title: '投资人维护',
												description: '负责管理投资者的基本信息、权限设置等方面，以确保投资者能够顺畅、安全地使用系统相关模块。',
											},
											{
												img: require('@/assets/images/service/system/c2.png'),
												title: '投资总览',
												description: '全面展示投资者的投资状况和风险暴露情况。提供与市场基准、同类投资者或历史业绩的对比功能，帮助投资者评估自己的投资表现。',
											},
											{
												img: require('@/assets/images/service/system/c3.png'),
												title: '投资报告',
												description: '投资报告模块负责生成和提供投资者所需的各类投资报告，以满足投资者的信息需求和决策支持。',
											},
											{
												img: require('@/assets/images/service/system/c4.png'),
												title: '持仓报告',
												description: '持仓报告模块专注于展示和分析投资者的持仓情况，帮助投资者了解自己的持仓结构和风险敞口。',
											},
										]
									},
									'fo': {
										title: '城堡家族办公室',
										description: '聚焦家办业务，包含股权、债权、证券、不动产等多投资品类管理功能；支持各类数据图表分析。通过合理的资产多样化投资及自研的资产配置模型来优化用户资产配置，实现在降低投资组合的波动和风险的同时保证及增加投资收益。',
										modules: [
											{
												img: require('@/assets/images/service/system/fo1.png'),
												title: '资产管理',
												description: '通过资产配置图览，系统直观的展示了对各大类领域资产配置投资情况，全面跟踪投资组合的最新收益及占比情况，在投资决策及管理配置中，针对可以针对投资策略和市场情况及时调整投资领域的配置比率。'
											},
											{
												img: require('@/assets/images/service/system/fo2.png'),
												title: '资产规划',
												description: '对个人及家庭资产制定和实行长期规划，综合考虑个人及家庭的收入、支出、储蓄、投资、保险等多个方面，可以在满足个人和家庭的长期财务需求的同时有效应对未来的潜在风险。'
											},
											{
												img: require('@/assets/images/service/system/fo3.png'),
												title: '财税法顾问',
												description: '有专业人士提供财务和税务领域的一对一服务，不仅服务于企业，也服务于个人和家庭，帮助在财务和税务方面做出更适合用户的决策。'
											},
											{
												img: require('@/assets/images/service/system/fo4.png'),
												title: '财富安全',
												description: '为客户提供全面的财务规划和安全策略，在确保客户的财富能够稳健增长的同时做好有效的风险管理保护客户的财富安全。'
											}
										]
									}
								},
            }
        },
				computed: {
						system() {
							return this.systemData[this.$route.params.key]
						}
				}
    }
</script>
<style lang="scss">
	.systems {
		margin-top: 40px;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		.system {
			width: calc(50% - 20px);
			margin-top: 0 !important;

			&.system-card {
				box-shadow: var(--shadow-lighter);
				background-color: #ffffff;
				padding: 32px;
				border-radius: 5px;
				margin-top: 30px;
				.inner-card {
					.image {
						display: flex;
						justify-content: center;
						align-items: start;
						img {
							width: 64px;
						}
					}

					.content {
						.title {
							font-size: 20px;
							font-weight: 500;
							margin-bottom: 8px;
							color: #0f0f11;
						}

						.description {
							margin-bottom: 12px;
							color: #0f0f11;
						}

						a {
							color: #092e6c;
							cursor: pointer;
							&:hover {
								color: #092e6c;
							}
						}
					}
				}
			}
		}
	}
</style>
