<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <div class="slider-area slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--70 mt--70"
                            text-align="left"
                            title="通用场景解决方案"
                            description="场景化解决方案,多维度赋能企业网络安全建设"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>

        <section class="rn-service-area rn-section-gap">
            <div class="small-container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            subtitle="金融资产管理方案"
                            description="城堡平台提供数据接口、系统搭建、部署与维护、HTML轻量级开发；强大的研发与优化能力，及时根据用户业务需要拓展应用及工具实现需求落地。当前已整合商品期货投研系统、城堡Data系统、量化交易系统等子项系统。"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <ServiceThree
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    :service-list="serviceList1"
										:item-in-one-line="4"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </section>

        <section class="rn-service-area rn-section-gap bg-color-lightest">
            <div class="small-container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            subtitle="企业管理与协作办公方案"
                            description="团队已自研多个企业协作及办公常用软件，且以拿到相关资质认证，具备研发体系完整、实施落地快捷等优势。当前已整合集成客户管理系统、财务管理系统、内外协作办公平台等子项系统。"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <ServiceThree
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    :service-list="serviceList2"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </section>

        <section class="rn-service-area rn-section-gap">
            <div class="small-container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            subtitle="业务需求实施方案"
                            description="团队研发实力强、响应快，可快捷、低成本实现用户需求梳理到开发落地完整流程。可提供系统定制开发及代运营服务、企业ISO代认证服务、企业软著代申请服务。"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <ServiceThree
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    :service-list="serviceList3"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </section>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Separator from '../components/elements/separator/Separator'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import ServiceThree from '../components/elements/service/ServiceThree'

export default {
    name: 'ServicePage',
    components: {ServiceThree, Separator, SectionTitle, Layout},
    data() {
        return {
            serviceList1: [
                {
                    image: require('@/assets/images/service/new/s1.png'),
                    title: '商品期货投研系统',
                },
                {
                    image: require('@/assets/images/service/new/s2.png'),
                    title: '城堡Data系统',
                },
                {
                    image: require('@/assets/images/service/new/s3.png'),
                    title: '城堡量化交易系统',
                },
                {
                    image: require('@/assets/images/service/new/s14.png'),
                    title: '城堡家族办公室',
                },
            ],
            serviceList2: [
                {
                    image: require('@/assets/images/service/new/s7.png'),
                    title: '客户管理系统',
                },
                {
                    image: require('@/assets/images/service/new/s4.png'),
                    title: '财务管理系统',
                },
                {
                    image: require('@/assets/images/service/new/s6.png'),
                    title: '内外协作办公平台',
                }
            ],
            serviceList3: [
                {
                    image: require('@/assets/images/service/new/s13.png'),
                    title: '定制开发及代运营',
                },
                {
                    image: require('@/assets/images/service/new/s12.png'),
                    title: '企业ISO代认证',
                },
                {
                    image: require('@/assets/images/service/new/s11.png'),
                    title: '企业软著代申请',
                },
            ]
        }
    }
}
</script>
