<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <p class="copyright-text text-center">Copyright © 2020-{{ new Date().getFullYear() }} 海南城堡数据科技有限公司All Rights Reserved    <a href="https://beian.miit.gov.cn/" style="color: unset">琼ICP备2024019490号</a></p>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>
