<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
            <div v-if="data.showCta" class="rn-callto-action clltoaction-style-default style-7">
                <div class="small-container">
                    <div class="footer">
                        <div class="logo">
                            <img src="@/assets/images/logo/logo-txt.png" alt="城堡数据"/>
                        </div>
                        <div class="company">
                            <div class="description">
                                城堡数据策承"技术领先、信誉第一、诚信合作、用户至上"的合作理念为用户提供性能出众的信息化集成解决方案,搭建集成数据分析、情报收集、资产管理的综合数据收集、分析、处理平台。
                            </div>
                            <div class="address">
                                <div>联系邮箱：<a href="mailto:public@citadeldata.cn">public@citadeldata.cn</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <Copyright v-if="data.showCopyright"/>

        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Button from '../../elements/button/Button'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Newsletter from './Newsletter'

    export default {
        name: 'Footer',
        components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>
<style lang="scss">
.footer {
    color: #0f0f11;
    display: flex;
    padding: 80px 0;
    .logo {
        width: 120px;
    }
    .company {
        margin-left: 40px;
    }
    .address {
        margin-top: 20px;
        display: flex;
        gap: 20px;
        a {
            color: unset;
        }
    }
}
</style>
