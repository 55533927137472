<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <div class="slider-area slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--70 mt--70"
                            text-align="left"
                            title="城堡数据"
                            description="技术领先、信誉第一、诚信合作、用户至上"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>

        <section class="rn-service-area area-2 mb--60 mt--60">
            <div class="small-container">
                <div class="row row--15">
                    <div class="col-lg-12">
                        <h4 class="mb--40" data-aos="fade-up">关于我们</h4>
                    </div>
                    <div class="col-lg-12">
                        <div class="content mb--20" data-aos="fade-up" data-aos-delay="150" style="color: #0f0f11">
                            城堡数据以技术服务为优势，以服务客户为先，以最大可能为客户做到最好！整个城堡技术平台以云计算、大数据技术为支撑，以产品化、平台化为重点，布局产业互联网，促进跨界融合，构建产业服务新生态，以专业的服务和负责任的态度服务用户。
                        </div>
                    </div>
                </div>
                <div class="row text-center mt--40" data-aos="fade-up" data-aos-delay="150">
                    <div class="col-sm-4">
                        <div class="icon">
                            <img class="p-img" src="@/assets/images/service/new/a2.png" alt="技术领先"/>
                        </div>
                        <div class="content mt--20">
                            <h5>技术领先</h5>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="icon">
                            <img class="p-img" src="@/assets/images/service/new/a1.png" alt="服务优质"/>
                        </div>
                        <div class="content mt--20">
                            <h5>服务优质</h5>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="icon">
                            <img class="p-img" src="@/assets/images/service/new/a3.png" alt="专业团队"/>
                        </div>
                        <div class="content mt--20">
                            <h5>专业团队</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="rwt-contact-area bg-color-lightest pt--20 pb--60" data-aos="fade-up">
            <div class="small-container">
                <div class="col-lg-12">
                    <h4 class="mt--60 mb--40">联系我们</h4>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" icon-class="cb-blue" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">邮箱</h4>
                                            <p><a href="mailto:public@citadeldata.cn">public@citadeldata.cn</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="false" class="col-lg-6 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" icon-class="cb-blue" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">地址</h4>
                                            <p>北京市海淀区硅谷亮城2号楼B座715室</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "@/components/icon/Icon.vue";

    export default {
        name: 'About',
        components: {Icon, SectionTitle, Layout},
        data() {
            return {}
        }
    }
</script>
<style lang="scss">
.area-2{
    .icon {
        .p-img {
            width: 64px;
            height: 64px;
        }
    }
    .more{
        display: inline-flex;
        color: #2261AA;
        img {
            width: 27px;
            height: 27px;
        }
        .i1{
            margin-left: -4px;
        }
        .i2{
            margin-left: -20px;
        }
    }
}
</style>
