<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <div class="slider-area slider-style-1 height-520">
            <div class="small-container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--70 mt--70"
                            text-align="left"
                            title="城堡数据大脑"
                            description="快速部署，快速应用，配置灵活"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <section class="rn-service-area rn-section-gap">
            <div class="small-container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-6">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="@/assets/images/service/new/d2.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h4 class="title theme-gradient2" data-aos="fade-up" data-aos-delay="280">
                                    城堡用户数据
                                </h4>
                                <p data-aos="fade-up" data-aos-delay="310">
                                    接入大批量用户行为和特征标签精准的C端用户数据，支持进行特定目标	C端用户广告推送、第三方商户接入，实现精准C端用户获取，提高产品服务的推广效率，降低成本提高收益。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Separator/>
        <section class="rn-service-area rn-section-gap">
            <div class="small-container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h4 class="title theme-gradient2" data-aos="fade-up" data-aos-delay="280">
                                    城堡风控引擎
                                </h4>
                                <p data-aos="fade-up" data-aos-delay="310">
                                    企业在系统应用开发中，每个产品都需要做风险控制，相较于传统的将风险控制逻辑写在具体的业务中，后期整个系统会异常复杂，不仅提高了系统维护的复杂性和成本，更额外增加了运维风险。
                                    <br>
                                    相较而言城堡风控引擎采用将所有风险集中化管理的方式，合作企业只需要通过城堡风控一个平台，使用相应的规则引擎，采用可视化配置，就可以实现集中平台化管理所有产品风险控制的目的。
                                    <br>
                                    城堡风控是一款轻量级实时风控引擎，适用于反欺诈应用场景，可以快速了解应用风险，进行量化风险，实现集中管理风险的目的。
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="@/assets/images/service/new/d1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'

    export default {
        name: 'ServicePage',
        components: { Separator, SectionTitle, Layout}
    }
</script>
